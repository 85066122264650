
import * as React from 'react';
import { Grid, MenuItem, Select, Button, TextField} from '@mui/material'
import Card from '@mui/material/Card';
import Navbar from '../../components/Navbar/Navbar.js'
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material"
import { Navigate } from "react-router-dom";
import ErrorModal from '../ErrorModal/ErrorModal';
import Signup from '../Signup/Signup.js'
import { useParams } from 'react-router';


export default function ReferralPage(props) {
    const { uuid } = useParams();
    const [state, setState2] = React.useState(null)    
    if (state) {
        console.log("loaded referral")
        var refstate = state.error ? null : state
        return (
          <div className="App">
                <Signup referral={refstate} />
          </div>
        );
    }
    function submit() {
        const url = process.env.REACT_APP_API_URL + "/refer/friend/" + uuid;
        const options = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            }
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.referral) {
                data.referral["uuid"] = uuid
                console.log(data.referral)
                setState2(data.referral)
            } else if (data.error) {
                setState2({error: true})
            }
        });
    }
    submit()
    return (
        <div className="App">
            <Navbar></Navbar>
            <CircularProgress color="inherit" />
        </div>
    )
}
