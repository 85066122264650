import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import FriendsPage from './components/FriendsPage/FriendsPage.js'
import AccountPage from './components/AccountPage/AccountPage.js'
import MirrorPage from './components/MirrorPage/MirrorPage.js'
import Login from './components/Login/Login.js'
import Signup from './components/Signup/Signup.js'
import ProfilePage from './components/ProfilePage/ProfilePage.js'
import ReferralPage from './components/ReferralPage/ReferralPage.js'
import VerifySuccessPage from './components/VerifySuccessPage/VerifySuccessPage.js'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { CookiesProvider } from "react-cookie";
import { ChakraProvider } from '@chakra-ui/react'
import LandingPage from './components/LandingPage/LandingPage.js'
import { createRoot } from 'react-dom/client';

import { extendTheme} from '@chakra-ui/react'
import { ColorModeScript } from "@chakra-ui/color-mode";

// 2. Extend the theme to include custom colors, fonts, etc

const config = {
    initialColorMode: "light",
    useSystemColorMode: true
}
const theme = extendTheme({ config})

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  // <React.StrictMode>
    <> 
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
    <CookiesProvider> 
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/dashboard" element={<App />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/friends" element={<FriendsPage />} />
          <Route path="/mirror" element={<MirrorPage />} />
          <Route path="/upgrade" element={<FriendsPage />} />
          <Route path="/account/:email" element={<AccountPage />} />
          <Route path="/refer/friend/:uuid" element={<ReferralPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/verify-success" element={<VerifySuccessPage />} />
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
    </>
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
