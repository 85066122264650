import * as React from 'react';
import Navbar from '../Navbar/Navbar.js'
import LeftNavbar from '../LeftNavbar/LeftNavbar';
import { useCookies } from "react-cookie";
import { CircularProgress, Grid, Button, TextField, Typography, Card } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Navigate } from "react-router-dom";
import ErrorModal from '../ErrorModal/ErrorModal';
import ReferralModal from '../ReferralModal/ReferralModal.js';
var i = 0;
export default function FriendsPage(props) {
  i++
  console.log(i)
  const [cookies, setCookie] = useCookies(['user']);
  const [state, setState2] = React.useState({ 
    redirect: null,
    account: null
  });
  const [state3, setState3] = React.useState({text: ""});
  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState({
      message: null,
      errors: null
  })

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setOpen(false);
  }

  const [openReferral, setOpenReferral] = React.useState(false);
  const handleOpenReferral = () => setOpenReferral(true);
  const handleCloseReferral = () => {
      setOpenReferral(false);
  }


  if (state.redirect) {
      return <Navigate to={state.redirect} />
  }
  

  const pressNo = (event) => {
    var index = event.target.id.slice(8)
    const email = state.account.messages.received[index]
    const url = process.env.REACT_APP_API_URL + "/decline-friend";
    const bearer = "Bearer " + cookies.token
    console.log(bearer)
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": bearer
        },
        body: JSON.stringify({
          "other_email": email
        })
    };
    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
        console.log(data);
        if (data.error) {
          console.log(data.error)
          if (data.message) {
              setError({
                  message: data.message,
                  errors: null
              })
          } 
          if (data.errors) {
              setError({
                  message: null,
                  errors: data.errors
              })
          }
          handleOpen()
        } else {
          console.log("request returned,")
          console.log(data)
          setState2({
            account: null,
            redirect: null
          })
          
        }
        
    });
  }
  const pressYes = (event) => {
    var index = event.target.id.slice(9)
    const email = state.account.messages.received[index]
    const url = process.env.REACT_APP_API_URL + "/accept-friend";
    const bearer = "Bearer " + cookies.token
    console.log(bearer)
    const options = {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": bearer
        },
        body: JSON.stringify({
          "other_email": email
        })
    };
    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
        console.log(data);
        if (data.error) {
          console.log(data.error)
          if (data.message) {
              setError({
                  message: data.message,
                  errors: null
              })
          } 
          if (data.errors) {
              setError({
                  message: null,
                  errors: data.errors
              })
          }
          handleOpen()
        } else {
          console.log("request returned,")
          console.log(data)
          setState2({
            account: null,
            redirect: null
          })
        }
        
    });
  }

  function viewProfile(friend) {
    console.log("friend")
    console.log(friend)
    setState2({
      redirect: `/account/${friend.email}`
    })
  }



  if (state.account && cookies.token != null) {
    var friendRows = []
    for (var x in state.account.friends) {
      var friendHabits = state.account.friends[x].habits
      var countFollowing = 0
      for (var y in friendHabits) {
        if (state.account.others_habits[y]?.id === friendHabits[y]) {
          countFollowing++
        }
      }
      friendRows.push((
        <Grid item xs={2.4} key={state.account.friends[x].name1} style= {{minWidth:'200px'}}>
          <Card variant="elevation" style={{backgroundColor:'#C1E6C5'}}>
            <Grid container>
              <Grid item xs={12} style={{paddingTop:'.4vh'}}>
                  <Typography sx={{float: 'left', paddingLeft: '1vw', flex: 'auto' }} gutterBottom>
                      {state.account.friends[x].name1}
                  </Typography>
                  <AccountCircleIcon style={{float:'right', paddingRight:'.7vw'}}></AccountCircleIcon>
              </Grid>
              <Grid item xs={12} style={{}}>
                <Button size="small" onClick={() => viewProfile(state.account.friends[x])}>View Profile</Button>
              </Grid>
              <Grid item xs={12} style={{height:'3vh'}}>
                <Typography>
                  {friendHabits.length} habits -- Following {countFollowing}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))
    }

    var recievedRows = []
    if (state.account.messages.received.length === 0) {
      recievedRows.push((
        <Grid item key={0} xs={12}>
          <Typography style={{float:'left', paddingLeft:'1em', paddingTop:'1em'}}>
            None For Now!
          </Typography>
        </Grid>
      ))
    }
    for (var x in state.account.messages.received) {
      recievedRows.push((
        <Grid item key={x} xs={12}>
          <Typography style={{float:'left', paddingLeft:'1em', paddingTop:'1em'}}>
            {state.account.messages.received[x]}
          </Typography>
          <Button variant="contained" id={"noButton"+x} onClick={pressNo} style={{marginTop:'1em', marginRight:'.5em', backgroundColor: '#EA3939'}}>No</Button>
          <Button variant="contained" id={"yesButton"+x} onClick={pressYes} style={{marginTop:'1em', backgroundColor: '#42A829'}}>Yes</Button>
        </Grid>
      ))
    }
    var sentRows = []
    if (state.account.messages.requested.length === 0) {
      sentRows.push((
        <Grid item key={"yeye2"} xs={12}>
          <Typography style={{float:'left', paddingLeft:'1em', paddingTop:'1em'}}>
            None For Now!
          </Typography>
        </Grid>
      ))
    }
    for (var x in state.account.messages.requested) {
      sentRows.push((
        <Grid key={x} item xs={12}>
          <Typography style={{float:'left', paddingLeft:'1em', paddingTop:'1em'}}>
            {state.account.messages.requested[x]}
          </Typography>
        </Grid>
      ))
    }
    console.log("loaded friends page")
    function addFriend() {
      const element = document.getElementById('friend-email').value
      if (validateEmail(element)) {
        console.log('email')
        const url = process.env.REACT_APP_API_URL + "/friend-request";
        const bearer = "Bearer " + cookies.token
        console.log(bearer)
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": bearer
            },
            body: JSON.stringify({
              "other_email": element
            })
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
              console.log(data.error)
              if (data.message) {
                  setError({
                      message: data.message,
                      errors: null
                  })
              } 
              if (data.errors) {
                  setError({
                      message: null,
                      errors: data.errors
                  })
              }
              handleOpen()
            } else {
              console.log("request returned,")
              console.log(data)
              if (data.message === "OK"){
                setState2({
                  account: null,
                  redirect: null
                })
              } else {
                handleOpenReferral()
              }
              
            }
            
        });
      }
    }
    
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };
    return (
        <div className="App">
            <Navbar username={state.account.name1} notifications={state.account.messages} loggedIn={true}></Navbar>
            <Grid container style={{height:'100%'}}>
                <LeftNavbar page="friends"/>
                <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                <ReferralModal habit={null} email={state3.text} open={openReferral} closeFunc={handleCloseReferral}></ReferralModal>
                <Grid item xs={true} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto', paddingLeft:'2vw'}}>
                  <Grid container rowSpacing={1.5} id="grid-container" style={{paddingTop:'5vh'}}>

                    <Grid item xs={8} style={{marginLeft:'4vw'}}>
                          <TextField id="friend-email" value={state3.text} onChange={event => setState3({ text: event.target.value })} error={!validateEmail(state3.text)} label="Enter their email address." variant="outlined" style={{float:'right', width:'90%', marginRight:'2%'}}></TextField>
                    </Grid>
                    <Grid item xs={2}>
                      <Button variant="contained" onClick={addFriend} style={{float:'left', height:'100%'}}>Add Friend</Button>
                    </Grid>
                    <Grid item xs={12} style={{height:"2vh"}}></Grid>
                    <Grid item xs={6}>
                      <Grid container>
                          <Grid item xs={12}>
                            <Typography style={{float:'left'}}>Friend Requests:</Typography>
                          </Grid>
                          {recievedRows}
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container>
                          <Grid item xs={12}>
                            <Typography style={{float:'left'}}>Pending Requests:</Typography>
                          </Grid>
                          {sentRows}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} className='bottom-border' style={{marginRight:'5vw'}}>
                      <Typography variant="h6" color="inherit" className="font-size" component="div" style={{width:'max-content',float:"right", marginTop:'2%', paddingRight:"1vw"}}>
                          Your Friends
                      </Typography>
                      
                    </Grid>
                    <Grid item xs={12} style={{paddingTop:"3vh"}}>
                      <Grid container rowSpacing={3} columnSpacing={3}>
                        {friendRows}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
        </div>
    );
  }
  console.log("making request")
  const url = process.env.REACT_APP_API_URL + "/friends";
  const bearer = "Bearer " + cookies.token
  console.log(bearer)
  const options = {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": bearer
      }
  };
  fetch(url, options)
  .then((response) => response.json())
  .then((data) => {
      console.log(data);
      if (data.error) {
        setState2({
          redirect: '/login'
        })
      } else {
        console.log("request returned, setting state.account")
        setState2({
          redirect: state.redirect,
          account: data
        })
      }
      
  });
  console.log("returning loading circle")
  return (
    <div className="App">
      <Navbar loggedIn={true}></Navbar>
      <CircularProgress color="inherit" />
  </div>
  )
}

