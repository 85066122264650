import * as React from 'react';
import {Grid} from '@mui/material'
import './MainContainer.css'
import YourHabits from '../../components/YourHabits/YourHabits.js'
import LeftNavbar from '../LeftNavbar/LeftNavbar';
import FriendsSummary from '../FriendsSummary/FriendsSummary';
import FriendsSection from '../FriendsSection/FriendsSection';
import VerificationModal from '../VerificationModal/VerificationModal';

export default function MainContainer(props) {
    const [state, setState2] = React.useState({ 
        markcomplete: false
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        props.handler({user: null, redirect: null})
    }
    console.log("here IIIIIII")
    console.log(props.account)
    console.log()
    if ((!props.account.email_confirmed || !props.account.phone_confirmed) && !open) {
      handleOpen()
    }
  

  return (
    <Grid container style={{height:'100%'}}>
        <LeftNavbar page="dashboard"/>
        <Grid item xs={true} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto', paddingLeft:'2vw'}}>
            <VerificationModal open={open} closeFunc={handleClose} account={props.account}></VerificationModal>
            <YourHabits account={props.account} handler={setState2} markcomplete={state.markcomplete} appHandler={props.handler}></YourHabits>
            {!state.markcomplete && <FriendsSummary account={props.account} appHandler={props.handler}></FriendsSummary>}
            {!state.markcomplete && <FriendsSection account={props.account} appHandler={props.handler}></FriendsSection>}
        </Grid>
    </Grid>

  );
}
