import * as React from 'react';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Modal, Box, TextField, Select } from '@mui/material';
import './NewHabitModal.css';
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import ErrorModal from '../ErrorModal/ErrorModal';

const style = {
    position: 'absolute', top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };




export default function NewHabitModal(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [state, setState2] = React.useState({ 
        remindersValue: 2,
        reminderTime: 9,
        remindersMe: 2,
        redirect: null
    });
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }
    if (state.redirect) {
        return <Navigate to={state.redirect} />
    }


    function submit() {
        const goal_amount = document.getElementById("goal_amount").value
        const timeframe = document.getElementById("timeframe").value
        const name1 = document.getElementById("name1").value
        const url = process.env.REACT_APP_API_URL + "/add-habit";

        // if (name1.indexOf(" ") >= 0) {
        //     console.log("contains space") 
        //     setError({
        //         message: "Name can't contain spaces.",
        //         errors: null
        //     })
        //     handleOpen()
        //     return
        // }
        // var hasNumber = /\d/; 
        // if (hasNumber.test(name1)) {
        //     setError({
        //         message: "Name can't contain numbers",
        //         errors: null
        //     })
        //     handleOpen()
        //     return
        // }

        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
            body: JSON.stringify({
                "name1": name1,
                "wait_day": false,
                "timeframe": timeframe,
                "goal_amount": goal_amount,
                "public1": true,
                "phone_bool": (state.remindersMe === 2 || state.remindersMe === 3),
                "email_bool": (state.remindersMe === 1 || state.remindersMe === 3),
                "reminders": state.remindersValue,
                "notification_time": state.reminderTime
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else if (data.habit_id) {
                props.closeFunc()
            }
        });
    }

    function handleChangeRemindersValue(e) {
        setState2({reminderTime: state.reminderTime, remindersValue: e.target.value, remindersMe: state.remindersMe})
    }

    function handleChangeRemindersTime(e) {
        setState2({reminderTime: e.target.value, remindersValue: state.remindersValue, remindersMe: state.remindersMe})
    }

    function handleChangeRemindersMe(e) {
        setState2({reminderTime: state.reminderTime, remindersValue: state.remindersValue, remindersMe: e.target.value})
    }

    return (
        <Modal
        open={props.open}
        onClose={props.closeFunc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                <h3>New Habit</h3>
                <hr></hr>
                <br></br>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    <p style={{width:'100%'}}>I want to: </p>
                    <TextField id="name1" required label="Habit Name" variant="outlined" style={{float:'left', width:'45%', marginLeft:'2%', paddingTop:'1vh', paddingRight:'10%'}} />
                    <TextField id="goal_amount" required label="Goal Amount" type='number' variant="outlined" style={{float:'left', width:'45%', marginLeft:'2%', paddingTop:'1vh', paddingRight:'3%'}} />
                    <p>time(s) every</p>
                    <TextField id="timeframe" required label="Timeframe" type='number' variant="outlined" style={{float:'left', width:'45%', marginLeft:'2%', paddingTop:'1vh', paddingRight:'3%'}} />
                    <p>days.</p>
                    <p style={{width:'100%'}}>If missed, alert others via </p>
                    <Select
                        style={{float:'left', width:'45%', marginLeft:'2%', marginTop:'1vh', marginRight:'3%'}}
                        labelId="demo-simple-select-label"
                        id="reminders_value"
                        value={state.remindersValue}
                        label="Plan"
                        onChange={handleChangeRemindersValue}
                    >
                        <MenuItem value={1}>Email</MenuItem>
                        <MenuItem value={2}>Text</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                    </Select>
                    <p>at </p>
                    <Select
                        style={{float:'left', width:'45%', marginLeft:'2%', marginTop:'1vh'}}
                        labelId="demo-simple-select-label"
                        id="reminders_time"
                        value={state.reminderTime}
                        label="Plan"
                        onChange={handleChangeRemindersTime}
                    >
                        <MenuItem value={1}>1 A.M.</MenuItem>
                        <MenuItem value={2}>2 A.M.</MenuItem>
                        <MenuItem value={3}>3 A.M.</MenuItem>
                        <MenuItem value={4}>4 A.M.</MenuItem>
                        <MenuItem value={5}>5 A.M.</MenuItem>
                        <MenuItem value={6}>6 A.M.</MenuItem>
                        <MenuItem value={7}>7 A.M.</MenuItem>
                        <MenuItem value={8}>8 A.M.</MenuItem>
                        <MenuItem value={9}>9 A.M.</MenuItem>
                        <MenuItem value={10}>10 A.M.</MenuItem>
                        <MenuItem value={11}>11 A.M.</MenuItem>
                        <MenuItem value={12}>12 P.M.</MenuItem>
                        <MenuItem value={13}>1 P.M.</MenuItem>
                        <MenuItem value={14}>2 P.M.</MenuItem>
                    </Select>
                    <p style={{width:'100%'}}>Remind me via </p>
                    <Select
                        style={{float:'left', width:'45%', marginLeft:'2%', marginTop:'1vh', marginRight:'3%'}}
                        labelId="demo-simple-select-label"
                        id="reminders_me"
                        value={state.remindersMe}
                        label="Plan"
                        onChange={handleChangeRemindersMe}
                    >
                        <MenuItem value={1}>Email</MenuItem>
                        <MenuItem value={2}>Text</MenuItem>
                        <MenuItem value={3}>Both</MenuItem>
                    </Select>
                </div>
                <div style={{width:'100%'}}>
                    <Button variant="contained" style={{marginTop:"1vh", marginBottom:'1vh'}} onClick={submit}>Create</Button>
                </div>
            </Box>
        </Modal>
    )

}
