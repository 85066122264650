import * as React from 'react';
import { Md1K, MdSettings } from 'react-icons/md'
import { ChakraProvider } from '@chakra-ui/react'
import { AppBar, Toolbar, ButtonBase, Typography } from '@mui/material';
import {Link as ReactLink} from 'react-router-dom'
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import {
  Box,
  LinkOverlay,
  LinkBox,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import './Navbar.css';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { Navigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { Hidden } from "@material-ui/core";
import MobileSidebar from '../MobileSidebar/MobileSidebar';

export default function BasicMenu(props) {
  const [cookies, setCookie] = useCookies(['user']);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState2] = React.useState({ 
    redirect: null
  });
  var showNotifIcon = false
  if (props.notifications != null && props.notifications.received.length !== 0) {
    showNotifIcon = true
  }
  const open = Boolean(anchorEl);
  if (state.redirect) {
    const redir = state.redirect
    console.log(redir)
    return <Navigate to={redir} />
  }
  const friendRedirect = (event) => {
    console.log('hello')
    setState2({
      redirect: '/friends'
    })
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleAccount = () => {
    setState2({
      redirect: '/profile'
    })
    setAnchorEl(null);
  };
  const handleLogout = () => {
    const url = process.env.REACT_APP_API_URL + "/logout";
    const bearer = "Bearer " + cookies.token
    const options = {
      'method': "POST",
      'headers': {
          'Accept': "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": bearer
      }
    };

    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
        console.log(data);
        if (!data.error) {
          setState2({
            redirect: '/login'
          })
        }
    });
  };

  return (
    <ChakraProvider>
        <div style={{marginBottom: '7vh'}} id="outer-container">
            <LinkBox>
                <Box position={'fixed'} w={'100%'} style={{zIndex: "200"}}>
                  <Flex
                    bg={'gray.50'}
                    color={'gray.600'}
                    minH={'60px'}
                    py={{ base: 2 }}
                    px={{ base: 4 }}
                    borderBottom={1}
                    borderStyle={'solid'}
                    borderColor={'gray.200'}
                    align={'center'}>
                    {props.loggedIn && <Flex
                      flex={{ base: 1, md: 'auto' }}
                      ml={{ base: -2 }}
                      display={{ base: 'flex', md: 'none' }}>
                    </Flex>}
                    <Flex flex={{ base: 1 }} justify={{ base: 'start', md: 'start' }}>
                      <LinkOverlay  href={props.loggedIn ? 'dashboard' : '/'}>
                          <Flex ml={[5, 5, 0]} flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
                              <img  src='../star.png' className="icon23"></img>
                              <Text
                                display={{ base: 'none', md:"flex"}}
                                textAlign={"left"}
                                fontFamily={'heading'}
                                color={'gray.800'}
                                style={{paddingTop:'14px', paddingLeft: '.5em'}}>
                                CrowdSupport.app
                              </Text>
                          </Flex>
                      </LinkOverlay>

                      {/* <Flex display={{ base: 'none', md: 'flex' }} ml={10}> */}
                      {/* </Flex> */}
                    </Flex>
                    {!props.loggedIn && <Stack
                      flex={{ base: 1, md: 0 }}
                      justify={'flex-end'}
                      direction={'row'}
                      spacing={6}>
                      <Button
                        as={'a'}
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'link'}
                        href={'/login'}>
                        Sign In
                      </Button>
                      <Button
                        fontSize={'sm'}
                        fontWeight={600}
                        color={'white'}
                        bg={'red.400'}
                        _hover={{
                          bg: 'red.300',
                        }}>
                        <Link as={ReactLink} to='/signup'>Sign Up</Link>
                      </Button>
                    </Stack>}

                      {props.loggedIn && <Flex>
                      <div id="notifIcon" style={{position:'relative', paddingRight:'1.8em'}}>
                        {showNotifIcon && <Link as={ReactLink} to='/friends' style={{height: '24px', position:'absolute', top:'50%', marginTop: '-12px'}} ><Icon  as={NotificationImportantIcon} /></Link>}
                        {!showNotifIcon && <Link as={ReactLink} to='/friends' style={{height: '24px', position:'absolute', top:'50%', marginTop: '-12px'}}><Icon as={NotificationsIcon}></Icon></Link>}
                      </div>
                        <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label='Options'
                          icon={<AccountCircleIcon/>}
                          variant='outline'
                        />
                        <MenuList>
                          <MenuItem ><Link as={ReactLink} to='/profile'>My Account</Link></MenuItem>
                          <MenuItem onClick={handleLogout} style={{color:'red'}}>Logout</MenuItem>
                        </MenuList>
                      </Menu>
                      </Flex>}
                  </Flex>

                </Box>
            </LinkBox>
          {/* <AppBar position="static" style={{backgroundColor:"#27B1FF"}}> */}
          {/*     <Toolbar variant="dense"> */}
          {/*         {props.loggedIn && <Hidden mdUp> */}
          {/*           <MenuIcon style={{paddingRight: '2vw', color:"#27B1FF", width: '24px'}}></MenuIcon> */}
          {/*         </Hidden>} */}
          {/*         <ButtonBase onClick={() => {setState2({redirect:'/dashboard'})}}> */}
          {/*             <Typography variant="h5" color="inherit" component="div"> */}
          {/*               Habit Tracker */}
          {/*             </Typography> */}
          {/*         </ButtonBase> */}
          {/*         {props.loggedIn && <div id="menu-button"><Menu */}
          {/*             id="basic-menu" */}
          {/*             anchorEl={anchorEl} */}
          {/*             open={open} */}
          {/*             onClose={handleClose} */}
          {/*             MenuListProps={{ */}
          {/*             'aria-labelledby': 'basic-button', */}
          {/*             }} */}
          {/*         > */}
          {/*             {props.username && <MenuItem style={{backgroundColor: "lightgray"}}>{props.username}</MenuItem>} */}
          {/*             <MenuItem onClick={handleAccount}>My account</MenuItem> */}
          {/*             <MenuItem onClick={handleLogout} style={{color:'red'}}>Logout</MenuItem> */}
          {/*         </Menu> */}
          {/*         <div id="menu-button"> */}
          {/*           {showNotifIcon && <NotificationImportantIcon style={{paddingRight:'1vw', color:'red'}} onClick={friendRedirect}> */}
          {/**/}
          {/*           </NotificationImportantIcon>} */}
          {/*           {!showNotifIcon && <NotificationsIcon style={{paddingRight:'1vw'}} onClick={friendRedirect}> */}
          {/**/}
          {/*           </NotificationsIcon>} */}
          {/*           <AccountCircleIcon  */}
          {/*               onClick={handleClick} */}
          {/*               aria-controls={open ? 'basic-menu' : undefined} */}
          {/*               aria-haspopup="true" */}
          {/*               aria-expanded={open ? 'true' : undefined} */}
          {/*           > */}
          {/*           </AccountCircleIcon> */}
          {/*         </div></div>} */}
          {/*     </Toolbar> */}
          {/* </AppBar> */}
          {props.loggedIn && <Hidden mdUp>
            <MobileSidebar pageWrapId={'page-wrap'} outerContainerId={'outer-container'}></MobileSidebar>
          </Hidden>}
      </div>
      </ChakraProvider> 
  );
}
