import * as React from 'react';
import { Grid, MenuItem, Select, Button, TextField, FormGroup, FormControlLabel, Typography, Checkbox} from '@mui/material'
import Card from '@mui/material/Card';
import './Signup.css'
import Navbar from '../../components/Navbar/Navbar.js'
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import ErrorModal from '../ErrorModal/ErrorModal';


export default function Signup(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [state, setState2] = React.useState({ 
        redirect: null,
        planValue: 'unlimited',
        emailValid: false,
        nameValid: false,
        phoneValid: false,
        passwordValid: false,
        cPassValid: false
      });
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })
    const [hasChecked, setChecked] = React.useState(false)

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }
    if (state.redirect) {
        return <Navigate to={state.redirect} />
    }
    var planValue = "free"
    function handleChange(e) {
        setState2({redirect: state.redirect, planValue: e.target.value, emailValid: state.emailValid, passwordValid: state.passwordValid, cPassValid: state.cPassValid, nameValid: state.nameValid, phoneValid: state.phoneValid})
    }

    function validateForm(email, password, confirmPassword, name1, phone_number) {
        const emailValid = email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        const passwordValid = password.length >= 6
        const confirmPasswordValid = confirmPassword === password
        var name1Valid= name1.length >= 3 
        const phoneValid = phone_number.match('^\[0-9]{4,15}$')
        // if (name1.indexof(" ") >= 0) {
        //     console.log("contains space") 
        //     name1valid = false
        //     seterror({
        //         message: "name can't contain spaces.",
        //         errors: null
        //     })
        //     handleopen()
        // }
        setState2({
            redirect: state.redirect,
            planValue: state.planValue,
            emailValid: !emailValid,
            passwordValid: !passwordValid,
            cpassValid: !confirmPasswordValid,
            nameValid: !name1Valid,
            phoneValid: !phoneValid
        })
        
        if (emailValid && passwordValid && confirmPasswordValid && name1Valid && phoneValid) {
            return true
        }
        return false
    }


    function submit(event) {
        const email = document.getElementById("email").value
        const password = document.getElementById("password").value
        const confirmPassword = document.getElementById("confirm-password").value
        const name1 = document.getElementById("name1").value
        var phone_number = document.getElementById("phone-number").value

        // const validForm = validateForm(email, password, confirmPassword, name1, phone_number)
        // console.log(validForm)
        // if (!validForm) {
        //     // handleOpen() 
        //     return
        // }
        
        phone_number = "+1" + phone_number
        console.log(email)
        console.log(password)
        var uuidVar = null
        if (props.referral != null) {
            uuidVar = props.referral.uuid
        }
        const url = process.env.REACT_APP_API_URL + "/signup";
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                email: email,
                password: password,
                name1: name1,
                phone_number: phone_number,
                confirm_password: confirmPassword,
                purchased_tracker: false,
                account_tier: state.planValue,
                uuid: uuidVar
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else if (data.token) {
                setCookie("token", data.token, { path: '/' });
                if (props.referral) {
                        setState2({redirect: "/account/" + props.referral.user_email})
                } else {
                    setState2({ redirect: "/dashboard" });
                }
            }
        });
    }
    return (
        <div className="App">
            <Navbar hasMirror={true} loggedIn={false}></Navbar>
            <Grid container style={{height:'100%'}}>
                <Grid item xs={12} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto'}}>
                    <Grid container style={{height:'100%'}}>
                        <Grid item xs={10} id="grid-card">
                            {props.referral && <Card variant="elevation" style={{marginBottom: '5vh', padding: '1em'}} className="scalingWidth">
                                <Typography variant="h4">
                                    {props.referral.user_name} wants you to hold them accountable for their habit: {props.referral.habit_name}
                                </Typography>
                            </Card>}
                            <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                            <Card variant="elevation" className='scalingWidth'> 
                                <h3>Signup</h3>
                                <hr></hr>
                                <TextField id="email" required error={state.emailValid} label="Email" variant="outlined" style={{float:'left', width:'80%', marginLeft:'10%'}} />
                                <TextField id="name1" required error={state.nameValid} label="Name" variant="outlined" style={{float:'left', width:'80%', marginLeft:'10%', paddingTop:'1vh'}} />
                                <TextField id="phone-number" error={state.phoneValid} required label="Phone #" variant="outlined" style={{float:'left', width:'80%', marginLeft:'10%', paddingTop:'1vh'}} />
                                <TextField id="password" required error={state.passwordValid} label="Password" type='password' variant="outlined" style={{float:'left', width:'80%', marginLeft:'10%', paddingTop:'1vh'}} />
                                <TextField id="confirm-password" required error={state.cPassValid} label="Confirm Password" type='password' variant="outlined" style={{float:'left', width:'80%', marginLeft:'10%', paddingTop:'1vh'}} />
                                <Select
                                    style={{float:'left', width:'80%', marginLeft:'10%', marginTop:'1vh'}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={state.planValue}
                                    label="Plan"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={'free'}>Free</MenuItem>
                                    <MenuItem value={'middle'}>Middle</MenuItem>
                                    <MenuItem value={'unlimited'}>Unlimited (currently free)</MenuItem>
                                </Select>
                                <FormGroup style={{width:'100%', paddingTop: '1em'}}>
                                  <FormControlLabel style={{width:'90%', marginLeft: '8%'}} control={<Checkbox onClick={() => {setChecked(!hasChecked)}}/>} label="I agree to recieve text and email notifications from this site." />
                                </FormGroup>
                                <div style={{width:'100%'}}>
                                    <Button variant="contained" style={{marginTop:"1vh", marginBottom:'1vh', width:'15%'}} disabled={!hasChecked} onClick={submit}>Signup</Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}
