import * as React from 'react';
import { Grid, Typography, ButtonBase} from '@mui/material'
import { Hidden } from "@material-ui/core";


export default function LeftNavbar(props) {
    console.log("page: " + props.page)
    
    let element = document.getElementById(props.page)
    console.log(element)
    if (element) {
        element.style.textDecoration = "underline"
    }
    
    return (
        <Hidden smDown>
            <Grid item xs={2} className="left-navbar" name="leftNavbar">
                <div className="center-vertical" style={{display:'flex', flexDirection:'column'}}>
                    <ButtonBase href="/dashboard">
                        <Typography id="dashboard" variant="h6" align="left" color="#27B1FF" component="div" className="underline">
                            Dashboard
                        </Typography>

                    </ButtonBase>
                    <ButtonBase href="/mirror">
                        <Typography id="mirror" variant="h6" align="left" color="#27B1FF" component="div" className="underline">
                            Mirror
                        </Typography>
                    </ButtonBase>
                    <ButtonBase href="/friends">
                        <Typography id="friends" variant="h6" align="left" color="#27B1FF" component="div" className="underline">
                            Friends
                        </Typography>
                    </ButtonBase>
                </div>
            </Grid>
        </Hidden>
       
        
    )
}