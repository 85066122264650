import * as React from 'react';
import { Grid, Button, TextField} from '@mui/material'
import Card from '@mui/material/Card';
import './Login.css'
import Navbar from '../../components/Navbar/Navbar.js'
import { useCookies } from "react-cookie";
import { Navigate } from "react-router-dom";
import ErrorModal from '../ErrorModal/ErrorModal';

export default function Login(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [state, setState2] = React.useState({ 
        redirect: null
      });
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }
    if (state.redirect) {
        return <Navigate to={state.redirect} />
    }
    function submit(event) {
        const email = document.getElementById("email").value
        const password = document.getElementById("password").value
        console.log(email)
        console.log(password)
        const url = process.env.REACT_APP_API_URL + "/login";
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else if (data.token) {
                setCookie("token", data.token, { path: '/' });
                setState2({ redirect: "/dashboard" });
            }
        });
    }
    return (
        <div className="App">
            <Navbar hasMirror={true} loggedIn={false}></Navbar>
            <Grid container style={{height:'100%'}}>
                <Grid item xs={12} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto'}}>
                    <Grid container style={{height:'100%'}}>
                        <Grid item xs={10} id="grid-card">
                            <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                            <Card variant="elevation" className='scalingWidth'> 
                                <h3>Login</h3>
                                <hr></hr>
                                <TextField id="email" required label="Email" variant="outlined" style={{float:'left', width:'90%', marginLeft:'2%'}} />
                                <TextField id="password" required label="Password" type='password' variant="outlined" style={{float:'left', width:'90%', marginLeft:'2%', paddingTop:'1vh'}} />
                                <div style={{width:'100%'}}>
                                    <Button variant="contained" style={{marginTop:"1vh", width: "12%", marginBottom:"1vh"}} onClick={submit}>Login</Button>
                                </div>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    )
}