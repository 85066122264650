import * as React from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Grid, Typography, Button, Menu, MenuItem} from '@mui/material'
import Card from '@mui/material/Card';
import './HabitCard.css'
import HabitGrid from '../HabitGrid/HabitGrid';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useCookies } from "react-cookie";
import ErrorModal from '../ErrorModal/ErrorModal';
import UpdateHabitModal from '../UpdateHabitModal/UpdateHabitModal';
import ReferralModal from '../ReferralModal/ReferralModal';

export default function HabitCard(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open2, setOpen] = React.useState(false);
    const [open3, setOpen3] = React.useState(false);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })

    const handleOpen = () => setOpen(true);
    const handleClose2 = () => {
        setOpen(false);
    }
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => {
        setOpen3(false);
        props.appHandler({
            account: null,
            redirect: null
        })
    }

    const [openReferral, setOpenReferral] = React.useState(false);
    const handleOpenReferral = () => setOpenReferral(true);
    const handleCloseReferral = () => {
        setOpenReferral(false);
    }

    function pressNo() {
        let element = document.getElementById("yesButton")
        if (element) {
            element.style.opacity = '0.5'
        }
        element = document.getElementById("noButton")
        if (element) {
            element.style.opacity = '1'
        }
        markCompleted(false)
    }
    function pressYes() {
        let element = document.getElementById("noButton")
        if (element) {
            element.style.opacity = '0.5'
        }
        element = document.getElementById("yesButton")
        if (element) {
            element.style.opacity = '1'
        }
        markCompleted(true)
    }
    function markCompleted(bool) {
        var temp = props.completedState
        temp[props.habit.id] = bool
        props.setCompletedState(temp)
    }

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function deleteHabit() {
        const url = process.env.REACT_APP_API_URL + "/delete-habit";
        console.log(props.habit.id)
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
            body: JSON.stringify({
                habit_id: props.habit.id
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else {
                props.appHandler({
                    account: null,
                    redirect: null
                })
            }
        });
    }

    function unfollowHabit() {
        const url = process.env.REACT_APP_API_URL + "/delete-follower";
        console.log(props.habit.id)
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
            body: JSON.stringify({
                habit_id: props.habit.id
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else {
                props.appHandler({
                    account: null,
                    redirect: null
                })
            }
        });
    }

    function followHabit() {
        const url = process.env.REACT_APP_API_URL + "/add-follower";
        console.log(props.habit.id)
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
            body: JSON.stringify({
                habit_id: props.habit.id,
                phone_bool: true,
                email_bool: true
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else {
                props.appHandler({
                    account: null,
                    redirect: null
                })
            }
        });
    }

    
    return (
        <Grid item xs={2.4} style= {{minWidth:'200px'}} className="phone_card">
            <ErrorModal error={error.message} errors={error.errors} open={open2} closeFunc={handleClose2}></ErrorModal>
            <UpdateHabitModal open={open3} closeFunc={handleClose3} habit={props.habit}></UpdateHabitModal>
            <ReferralModal habit={props.habit} email={null} open={openReferral} closeFunc={handleCloseReferral}></ReferralModal>
            <Card variant="elevation" className={props.habit.days[props.habit.days.length - 1] === 1 ? "habit-card-green":"habit-card-red"}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography sx={{float: 'left', paddingLeft: '1vw', flex: 'auto' }} gutterBottom>
                            {props.habit.name1}
                        </Typography>
                        {props.owner && <Button variant="contained" onClick={handleOpenReferral} className={props.habit.followers.length == 1 ? "button-glow-red" : ""} style={{float:'right', paddingBottom:'2px', borderColor: '#F18282', marginTop:'3px', paddingTop: "0px", paddingLeft:'0px', paddingRight:'0px', marginRight:'5px', maxWidth:"32px", minWidth: '32px'}}><AddCircleOutlineIcon fontSize="medium"/></Button>
                        }
                        {props.owner && !props.friendsPage && <div><Button variant="contained" onClick={handleClick} className={props.habit.days[props.habit.days.length - 1] === 1 ? "habit-card-green icon22":"habit-card-red icon22"} style={{float:'right', paddingBottom:'2px', borderColor: '#F18282', marginTop:'3px', paddingTop: "0px", paddingLeft:'0px', paddingRight:'0px', marginRight:'5px', maxWidth:"32px", minWidth: '32px'}}>
                            <img src='../icons8-settings-48.png' className="icon22"></img>
                        </Button> 
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            {props.username && <MenuItem style={{backgroundColor: "lightgray"}}>{props.username}</MenuItem>}
                            <MenuItem onClick={handleOpen3}>Update Habit</MenuItem>
                            <MenuItem style={{color:'red'}} onClick={deleteHabit}>Delete</MenuItem>
                        </Menu></div>}
                        {!props.owner && !props.friendsPage && <div><Button variant="contained" onClick={handleClick} className={props.habit.days[props.habit.days.length - 1] === 1 ? "habit-card-green icon22":"habit-card-red icon22"} style={{float:'right', paddingBottom:'2px', borderColor: '#F18282', marginTop:'3px', paddingTop: "0px", paddingLeft:'0px', paddingRight:'0px', marginRight:'5px', maxWidth:"32px", minWidth: '32px'}}>
                            <img src='../icons8-menu-vertical-48.png' className="icon22"></img>
                        </Button> 
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                            'aria-labelledby': 'basic-button',
                            }}
                        >
                            {props.username && <MenuItem style={{backgroundColor: "lightgray"}}>{props.username}</MenuItem>}
                            <MenuItem style={{color:'red'}} onClick={unfollowHabit}>Unfollow</MenuItem>
                        </Menu></div>}
                        {props.friendsPage && props.followed && <Button variant="contained" onClick={unfollowHabit} className={"icon22"} style={{float:'right', paddingBottom:'2px', borderColor: '#F18282', marginTop:'3px', paddingTop: "0px", paddingLeft:'0px', paddingRight:'0px', marginRight:'5px'}}>
                            <Typography variant="caption">Unfollow</Typography>
                        </Button>}
                        {props.friendsPage && !props.followed && <Button variant="contained" onClick={followHabit} className={"icon22"} style={{float:'right', paddingBottom:'2px', borderColor: '#F18282', marginTop:'3px', paddingTop: "0px", paddingLeft:'0px', paddingRight:'0px', marginRight:'5px'}}>
                            <Typography variant="caption">Follow</Typography>
                        </Button>}
                    </Grid>
                    <Grid item xs={12} style={{height:".5em"}}>
                        {!props.markcomplete && <Typography sx={{float: 'left', paddingLeft: '1vw', flex: 'auto' }} variant="caption" gutterBottom>
                            {props.habit.goal_amount} {props.habit.goal_amount === 1 && "time"}{props.habit.goal_amount !== 1 && "times"} every {props.habit.timeframe !== 1 && props.habit.timeframe} day{props.habit.timeframe !== 1 && "s"}
                        </Typography>}
                        {props.markcomplete && <Typography sx={{float: 'left', paddingLeft: '1vw', flex: 'auto' }} variant="caption" gutterBottom>
                            Did Today:
                        </Typography>}
                    </Grid>
                    <Grid item xs={12} id='habit-display-container'>
                        {!props.markcomplete && <HabitGrid days={props.habit.days}></HabitGrid>}
                        {props.markcomplete && <div>
                            <Button variant="contained" id="noButton" onClick={pressNo} style={{marginTop:'1em', marginRight:'.5em', backgroundColor: '#EA3939'}}>No</Button>
                            <Button variant="contained" id="yesButton" onClick={pressYes} style={{marginTop:'1em', backgroundColor: '#42A829'}}>Yes</Button>
                        </div>}
                    </Grid>
                    <Grid item xs={12} id='habit-card-footer'>
                        <Grid container>
                            <Grid item xs={8}>
                                {!props.owner && <Typography style={{float: "left", paddingLeft:'1vw'}}>{props.owner_name}</Typography>}
                                {props.owner && props.habit.didToday && <><CheckBoxIcon style={{float:'left', paddingLeft:'.5vw'}}></CheckBoxIcon>
                                <Typography style={{float: "left", paddingLeft:'.3vw'}}>Did Today</Typography></>}
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{float:'right'}}>{props.habit.streak}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <img src='../icons8-fire-48.png' id="streak-image"></img>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
            </Card>
        </Grid>
    )
}
