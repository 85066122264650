import * as React from 'react';
import { Grid, Typography, Button} from '@mui/material'
import './YourHabits.css'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import HabitCard from '../../components/HabitCard/HabitCard.js'
import { useCookies } from "react-cookie";
import NewHabitModal from '../NewHabitModal/NewHabitModal';

export default function YourHabits(props) {
    const [cookies, setCookie] = useCookies(['user']);
    var state2 = {}
    for (var i=0; i<props.account.habits.length; i++) {
        state2[props.account.habits[i].id] = null
    }
    
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        props.appHandler({user: null, redirect: null})
    }

    const [completedState, setCompletedState] = React.useState(state2);

    console.log(completedState)


    var rows = []
    for (var i=0; i<props.account.habits.length; i++) {
        rows.push(
            <HabitCard habit={props.account.habits[i]} key={props.account.habits[i].id} owner={true} owner_name={props.account.name1} markcomplete={props.markcomplete} completedState={completedState} setCompletedState={setCompletedState} appHandler={props.appHandler}></HabitCard>
        )
    }

    function submitCompleted() {
        console.log(completedState)
        var allMarked = true
        var completed = []
        for (var x in completedState) {
            console.log(x)
            console.log(completedState[x])
            if (completedState[x] == null) {
                allMarked = false
            }
            if (completedState[x]) {
                completed.push(x)
            }
        }
        if (allMarked) {
            console.log(completedState)
            var count = 0
            for (var i in completed) {
                const url = process.env.REACT_APP_API_URL + "/habit-success";
                const bearer = "Bearer " + cookies.token
                console.log(bearer)
                const options = {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json;charset=UTF-8",
                        "Authorization": bearer
                    },
                    body: JSON.stringify({
                        habit_id: parseInt(completed[i])
                    })
                };
                console.log(options)
                fetch(url, options)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data);
                    console.log(allMarked.length - 1)
                    if (count === completed.length - 1) {
                        props.appHandler({
                            redirect: null,
                            account: null
                        })
                    }
                    count++
                })
            }
            

        } else {
            alert("You must mark all habits either complete or incomplete.")
        }
    }

    function markComplete() {
        props.handler({
            markcomplete: true
        })
    }
    var markCompleteButton = (
        <Grid item xs={12} style={{marginRight:'5vw'}}>
            <Button variant="contained" style={{float:'right'}} onClick={markComplete}>Mark Complete</Button>
        </Grid>
    )
    return (
        <Grid container rowSpacing={1.5} id="grid-container">
            <Grid item xs={12} className='bottom-border' style={{marginRight:'5vw'}}>
                <Button variant="contained" onClick={handleOpen} className={props.account.habits.length == 0 ? "button-glow" : ""} style={{float:'right', height:'50%',width:"1.5%",marginTop:'1.5%', marginBottom:'1%'}}><AddCircleOutlineIcon fontSize="medium"/></Button>
                <NewHabitModal open={open} closeFunc={handleClose}></NewHabitModal>
                <Typography variant="h6" color="inherit" className="font-size" component="div" style={{width:'max-content',float:"right", marginTop:'2%', paddingRight:"1vw"}}>
                    Your Habits
                </Typography>
                
            </Grid>
            {props.account.habits.length !== 0 && !props.markcomplete && markCompleteButton}
            <Grid item xs={12} style={{paddingLeft:'3vw', paddingRight:'.5vw', paddingBottom:'7vh', marginRight:'5vw'}}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    {rows}
                    <Grid item xs={12}>
                        {/* TODO Update the below href */}
                        {!props.markcomplete && props.account.account_tier !== "unlimited" && <Button variant="contained" href="" id="upgrade-button">Upgrade</Button>}
                        {props.markcomplete && <Button variant="contained" onClick={submitCompleted} id="submit-button">Submit</Button>}
                    </Grid>
                </Grid>
            </Grid>
            <br></br>
            <br></br>
        </Grid>
    )
}
