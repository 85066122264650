import * as React from 'react';
import Button from '@mui/material/Button';
import { Modal, Box, TextField } from '@mui/material';
import './VerificationModal.css';
import { useCookies } from "react-cookie";
import ErrorModal from '../ErrorModal/ErrorModal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };




export default function VerificationModal(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [state, setState2] = React.useState({ 
        sentCode: false,
        sentEmail: false
    });
    const [open, setOpen] = React.useState(false);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    function submitPhone() {
        const url = process.env.REACT_APP_API_URL + "/confirmPhone";
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else if (!data.error) {
                console.log('hiya')
                setState2({sentCode: true, sentEmail: state.sentEmail})
            }
        });
    }

    function verifyPhone() {
        const authCode = document.getElementById("auth-code-input").value
        const url = process.env.REACT_APP_API_URL + "/verifyPhone";
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
            body: JSON.stringify({
                "verification_code": authCode
            }),
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else if (!data.error && data.message === "OK") {
                console.log('hiya')
                props.account.phone_confirmed = true
                setState2({sentCode: false, sentEmail: state.sentEmail})
            }
        });
    }

    function submitEmail() {
        const url = process.env.REACT_APP_API_URL + "/confirmEmail";
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: cookies["token"]
            },
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else {
                console.log('inya')
                setState2({sentCode: state.sentCode, sentEmail: true})
            }
        });
    }


    return (
        <Modal
        open={props.open}
        onClose={props.closeFunc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                {(!props.account.phone_confirmed && !state.sentCode) && <div>
                    <h3 style={{width:'100%'}}>Verifying Phone Number: {props.account.phone_number} </h3>
                    <div style={{width:'100%'}}>
                        <Button variant="contained" style={{marginTop:"1vh", marginBottom:'1vh', display: 'table', margin: '0 auto'}} onClick={submitPhone}>Send Code</Button>
                    </div>
                </div>}
                {(state.sentCode && !state.sentEmail) && <div>
                    <TextField required id="auth-code-input" label="Auth code" variant="outlined"></TextField>
                    <Button variant="contained" style={{marginTop:"1.3vh", marginBottom:'1vh', float: "right"}} onClick={verifyPhone}>Verify Phone</Button>
                    </div>}
                {(props.account.phone_confirmed && !state.sentEmail) && <div>
                    <h3 style={{width:'100%'}}>Verifying Email: {props.account.email} </h3>
                    <div style={{width:'100%'}}>
                        <Button variant="contained" style={{marginTop:"1vh", marginBottom:'1vh', display: 'table', margin: '0 auto'}} onClick={submitEmail}>Send Email</Button>
                    </div>
                </div>}
                {state.sentEmail && <div>
                    <h3 style={{width:'100%'}}>Follow link sent to: {props.account.email}, make sure to check spam folder and marketing folder.</h3>
                    </div>}
                
            </Box>
        </Modal>
    )

}
