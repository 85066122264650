import { ReactElement } from 'react';
import { Box, SimpleGrid, Icon, Text, Stack, Flex } from '@chakra-ui/react';
import { FcTodoList, FcManager, FcPositiveDynamic} from 'react-icons/fc';

interface FeatureProps {
  title: string;
  text: string;
  icon: ReactElement;
}

const Feature = ({ title, text, icon }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={'center'}
        justify={'center'}
        color={'white'}
        rounded={'full'}
        bg={'gray.100'}
        mb={1}>
        {icon}
      </Flex>
      <Text color={'white'} pt={5} fontWeight={600}>{title}</Text>
      <Text color={'gray.600'}>{text}</Text>
    </Stack>
  );
};

export default function SimpleThreeColumns() {
  return (
    <Box p={4} bg={'gray.800'} h={{base:1000, md: 700}}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10} px={'8vw'} pt={'20vh'} mb={'3vh'}>
        <Feature
          icon={<Icon as={FcTodoList} w={10} h={10} />}
          title={'Add Your Habits'}
          text={
            'Create habits that you plan to hold yourself to at risk of embarrassment. Choose a notification time for you and all your friends to be alerted if you fail. After completing a habit, mark it complete to save face.'
          }
        />
        <Feature
          icon={<Icon as={FcManager} w={10} h={10} />}
          title={'Add Your Friends'}
          text={
            'Once you\'ve created a habit, refer your friends to follow your habits. Once they\'ve followed your habits, they\'ll start recieving texts if you fail.'
          }
        />
        <Feature
          icon={<Icon as={FcPositiveDynamic} w={10} h={10} />}
          title={'Profit'}
          text={
            'Large accomplishments require planning and consistent effort, you\'re off to a good start on your goals'
          }
        />
      </SimpleGrid>
    </Box>
  );
}
