import * as React from 'react';
import Navbar from '../Navbar/Navbar.js'
import LeftNavbar from '../LeftNavbar/LeftNavbar';
import { useCookies } from "react-cookie";
import { Grid } from "@mui/material"
import { CircularProgress } from "@mui/material"
import { Navigate } from "react-router-dom";

var i = 0;
export default function MirrorPage(props) {
  i++
  console.log(i)
  const [cookies, setCookie] = useCookies(['user']);
  const [state, setState2] = React.useState({ 
    redirect: null,
    account: null
  });
  if (state.redirect) {
      return <Navigate to={state.redirect} />
  }

  if (state.account && cookies.token != null) {
    console.log("loaded main dashboard")
    return (
      <div className="App">
        <Navbar username={state.account.name1} notifications={state.account.messages} loggedIn={true}></Navbar>
        <Grid container style={{height:'100%'}}>
          <LeftNavbar page="mirror"/>
          <h1>Coming soon (maybe)</h1>
        </Grid>
      </div>
    );
  }
  console.log("making request")
  const url = process.env.REACT_APP_API_URL + "/dashboard";
  const bearer = "Bearer " + cookies.token
  console.log(bearer)
  const options = {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": bearer
      }
  };
  fetch(url, options)
  .then((response) => response.json())
  .then((data) => {
      console.log(data);
      if (data.error) {
        setState2({
          redirect: '/login'
        })
      } else {
        console.log("request returned, setting state.account")
        setState2({
          redirect: state.redirect,
          account: data
        })
      }
      
  });
  console.log("returning loading circle")
  return (
    <div className="App">
      <Navbar loggedIn={true}></Navbar>
      <CircularProgress color="inherit" />
  </div>
  )
}

