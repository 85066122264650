import * as React from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import { useCookies } from "react-cookie";
import './ReferralModal.css'
import ErrorModal from '../ErrorModal/ErrorModal';
import ClipboardJS from 'clipboard';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };




export default function ReferralModal(props) {
    new ClipboardJS('.btn');
    const [cookies, setCookie] = useCookies(["user"]);
    const [error, setError] = React.useState({
        message: null,
        errors: null
    })
    const [open, setOpen] = React.useState(false);
  
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    }

    const [currentState, setCurrentState] = React.useState({
        sentEmail: false,
        generatedLink: false,
        link: ""
    })

    function apiCall(json, callback) {
        const url = process.env.REACT_APP_API_URL + "/refer";
        const bearer = "Bearer " + cookies.token
        console.log(bearer)
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                "Authorization": bearer
            },
            body: JSON.stringify(json)
        };
        fetch(url, options)
        .then((response) => response.json())
        .then((data) => {
            console.log(data);
            if (data.error) {
                console.log(data.error)
                if (data.message) {
                    setError({
                        message: data.message,
                        errors: null
                    })
                } 
                if (data.errors) {
                    setError({
                        message: null,
                        errors: data.errors
                    })
                }
                handleOpen()
            } else {
                console.log("request returned,")
                console.log(data)
                callback(data)
            }
            
        });
    }

    function sendEmail(e) {
        console.log(e.target.id)
        console.log("email "+ props.email)
        
        const email = document.getElementById("friendEmail").value
        apiCall({
            email: e.target.id !== "getLink" ? email : "fakeemail@gmail.com",
            habit_id: props.habit !== null ? props.habit.id : 0,
            habit_bool: props.habit !== null ? true : false,
            send_bool: e.target.id !== "getLink"
        }, (data) => {
            console.log(data)
            if (data.link) {
                setCurrentState({
                    sentEmail: false,
                    generatedLink: true,
                    link: data.link
                })
            } else {
                setCurrentState({
                    sentEmail: true,
                    generatedLink: false,
                    link: ""
                })
            }
        })
    }

    function onClose3() {
        setCurrentState({
            sentEmail: false,
            generatedLink: false,
            link: ""
        })
        props.closeFunc()
    }




    return (
        <Modal
        open={props.open}
        onClose={onClose3}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <ErrorModal error={error.message} errors={error.errors} open={open} closeFunc={handleClose}></ErrorModal>
                { !props.habit && !currentState.generatedLink && !currentState.sentEmail && <h2>User does not exist. Refer them?</h2>}
                { props.habit && !currentState.generatedLink && !currentState.sentEmail && <h2>Refer a friend to hold you accountable to {props.habit.name1} {props.habit.goal_amount} time(s) every {props.habit.timeframe} days.</h2>}
                { currentState.generatedLink && <h2>Send them this link:</h2>}
                { currentState.sentEmail && <h2>Sent!</h2>}
                { !currentState.generatedLink && !currentState.sentEmail && <div style={{width:'100%'}}>
                    <div className='centerDiv'>
                        <h5 style={{display:'inline-block'}}>Generate Link to Share: </h5>
                        <Button style={{display:'inline-block'}} id="getLink" onClick={sendEmail}>Get Link</Button>
                    </div>
                    <h4 style={{marginLeft:"3em"}}>or</h4>
                    <div className="centerDiv">
                        {props.email && <h5 style={{display:'inline-block'}}>({props.email})</h5>}
                        {!props.email && <Box width={'90%'} style={{display:'inline-block'}}><TextField id="friendEmail" fullWidth label="Enter your friend's email." style={{display:'inline-block', verticalAlign: "middle", width: "90% !important"}} sx={{width:'90%'}}></TextField></Box>}
                        <Button style={{display:'inline-block', verticalAlign: "middle"}} onClick={sendEmail}>Send Email</Button>
                    </div>
                </div>}
                { currentState.generatedLink && <div className="centerDiv" style={{height:'41px'}}>
                    <input id="foo" readOnly style={{height: '35px', width: '80%', display: "inline-block", verticalAlign: "middle"}} value={currentState.link}/>
                    <button className="btn" data-clipboard-target="#foo" style={{display: "inline-block", verticalAlign: "middle"}}>   
                        <img src="../clippy2.png" alt="Copy to clipboard" />
                    </button>
                </div>}
                
            </Box>
        </Modal>
    )

}
