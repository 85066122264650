import * as React from 'react';
import { Grid, Typography, Button} from '@mui/material'
import HabitCard from '../../components/HabitCard/HabitCard.js'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function FriendsSection(props) {
    var rows = {}
    var others_names = {}
    for (var i=0; i<props.account.others_habits.length; i++) {
        if (rows[props.account.others_habits[i].owner_email]) {
            rows[props.account.others_habits[i].owner_email].push(
                <HabitCard habit={props.account.others_habits[i]} key={props.account.others_habits[i].id} owner={false} owner_name={props.account.others_habits[i].owner_name} appHandler={props.appHandler}></HabitCard>
            )
        } else {
            rows[props.account.others_habits[i].owner_email] = [<HabitCard habit={props.account.others_habits[i]} key={props.account.others_habits[i].id} owner={false} owner_name={props.account.others_habits[i].owner_name} appHandler={props.appHandler}></HabitCard>]
            others_names[props.account.others_habits[i].owner_email] = props.account.others_habits[i].owner_name
        }
    }
    var friendRows = []
    for (const [key, value] of Object.entries(rows)) {
        friendRows.push(
            <div key={key}>
                <Grid item xs={12} className='friend-name'>
                    <Typography style = {{float:'left', paddingLeft:'1%'}}>
                        {others_names[key]}:
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingLeft:'3vw', paddingRight:'.5vw', marginRight:'5vw'}}>
                    <Grid container rowSpacing={3} columnSpacing={3}>
                        {value}
                    </Grid>
                </Grid>
            </div>
        )
    }
    return (
        <Grid container rowSpacing={1.5} id="grid-container">
            <Grid item xs={12} className='bottom-border' style={{marginRight:'5vw', marginBottom: '7vh'}}>
                <Button variant="contained" href="friends" style={{float:'right', height:'50%',width:"1.5%",marginTop:'1.5%', marginBottom:'1%'}}><AddCircleOutlineIcon fontSize="medium"/></Button>
                <Typography variant="h6" color="inherit" className="font-size" component="div" style={{width:'max-content',float:"right", marginTop:'2%', paddingRight:"1vw"}}>
                    Friends
                </Typography>
                
            </Grid>
            {friendRows}
            <br></br>
            <br></br>
        </Grid>
    )
}
