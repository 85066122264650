import * as React from 'react';
import { Modal, Box } from '@mui/material';
import { useCookies } from "react-cookie";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4
  };




export default function ErrorModal(props) {
    const [cookies, setCookie] = useCookies(["user"]);
    const [state, setState2] = React.useState({ 
        sentCode: false,
        sentEmail: false
    });
    var errorArray = []
    if (props.errors) {
        for (var x in props.errors) {
            errorArray.push(<div>
                <h4>Value: {props.errors[x].value}</h4>
                <h4>Message: {props.errors[x].msg}</h4>
            </div>)
        }
    }



    return (
        <Modal
        open={props.open}
        onClose={props.closeFunc}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {props.error && <div>
                    <h3 style={{width:'100%'}}>Error</h3>
                    <h4>Problem: {props.error}</h4>
                </div>}
                {props.errors && <div>
                    <h3 style={{width:'100%'}}>Errors: </h3>
                    {errorArray}
                </div>}            
            </Box>
        </Modal>
    )

}
