import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import { ChakraProvider } from '@chakra-ui/react'
import * as React from 'react';
import LandingPageNavbar from '../LandingPageNavbar/LandingPageNavbar.js'
import CallToActionWithVideo from '../CallToActionWithVideo/CallToActionWithVideo.js'
import LandingPageSecondSection from '../LandingPageSecondSection/LandingPageSecondSection.js'
import LandingPageFooter from '../LandingPageFooter/LandingPageFooter.js'
import { useRef } from 'react'
export default function LandingPage(props) {
    const firstItemRef = useRef(null)
    return (<ChakraProvider><div style={{overflowY: "scroll"}}>
        <LandingPageNavbar />
        <CallToActionWithVideo secondSection={firstItemRef}/>
        <LandingPageSecondSection />
        <div ref={firstItemRef}></div>
        <LandingPageFooter />
    </div></ChakraProvider>)
}
