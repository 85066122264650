import Navbar from '../Navbar/Navbar.js'
import { useCookies } from "react-cookie";
import { Card, Grid, MenuItem, Select, Button, TextField, Typography} from '@mui/material'
import { CircularProgress } from "@mui/material"
import { Navigate } from "react-router-dom";
export default function VerifySuccessPage(props) {
    return (
      <div className="App">
        <Navbar loggedIn={false}></Navbar>
        <Grid container style={{height:'100%'}}>
            <Grid item xs={12} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto'}}>
                <Grid container style={{height:'100%'}}>
                    <Grid item xs={10} id="grid-card">
                        <Card variant="elevation" style={{padding: '2em'}} className='scalingWidth'> 
                            <Typography variant="h3">
                                Email Confirmed!
                            </Typography>
                            <p>Please close this window.</p>
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
      </div>
    )
}
