import * as React from 'react';
import Navbar from '../Navbar/Navbar.js'
import LeftNavbar from '../LeftNavbar/LeftNavbar';
import { useCookies } from "react-cookie";
import { CircularProgress, Grid, Typography } from "@mui/material"
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import VerificationModal from '../VerificationModal/VerificationModal';
import { Navigate } from "react-router-dom";
import { useParams } from 'react-router';
import HabitCard from '../../components/HabitCard/HabitCard.js'


export default function AccountPage(props) {
    const { email } = useParams();

    const [cookies, setCookie] = useCookies(['user']);
    const [state, setState2] = React.useState({ 
        redirect: null,
        account: null,
        friend: null
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setState2({user: null, redirect: null, friend: null})
    }
    console.log("here IIIIIII")
    console.log()
    if (state.account) {
        if ((!state.account.email_confirmed || !state.account.phone_confirmed) && !open) {
          handleOpen()
        }
    }
    if (state.redirect) {
        return <Navigate to={state.redirect} />
    }

    if (state.account && cookies.token != null) {
        console.log("loaded friend account page")

        var friendsHabits = []
        for (var x in state.friend.habits) {

            // console.log(state.account.others_habits.includes(state.friend.habits[x]))
            // console.log(state.account.others_habits)
            // console.log(state.friend.habits[x].id)
            friendsHabits.push((
                <HabitCard habit={state.friend.habits[x]} owner={false} owner_name={state.friend.name1} friendsPage={true} followed={state.account.others_habits.includes(state.friend.habits[x].id)} appHandler={setState2}></HabitCard>
              ))
        }


        return (
            <div className="App">
                <Navbar username={state.account.name1} notifications={state.account.messages} loggedIn={true}></Navbar>
                <Grid container style={{height:'100%'}}>
                    <LeftNavbar page="friends"/>
                    <VerificationModal open={open} closeFunc={handleClose} account={state.account}></VerificationModal>
                    <Grid item xs={true} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto',paddingRight:'4vw', paddingLeft:'2vw'}}>
                        <Grid container columnSpacing={1.5} rowSpacing={1.5} id="grid-container" style={{paddingTop:'5vh'}}>
                            <Grid item xs={10} className='bottom-border'>
                                <AccountCircleIcon style={{float:'left', paddingLeft:'2vw'}}></AccountCircleIcon>
                                <Typography variant="h6" color="inherit" className="font-size" component="div" style={{width:'max-content',float:"left", paddingLeft:"1.5vw"}}>
                                    {state.friend.name1}
                                </Typography>
                            </Grid>
                            {friendsHabits}
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
    console.log("making request")
    const url = process.env.REACT_APP_API_URL + `/friend/${email}`;
    const bearer = "Bearer " + cookies.token
    console.log(bearer)
    const options = {
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8",
            "Authorization": bearer
        }
    };
    fetch(url, options)
    .then((response) => response.json())
    .then((data) => {
        console.log(data);
        if (data.error) {
            setState2({
            redirect: '/login'
            })
        } else {
            console.log("request returned, setting state.account")
            setState2({
            redirect: state.redirect,
            account: data.user,
            friend: data.friend
            })
        }
        
    });
    console.log("returning loading circle")
    return (
        <div className="App">
        <Navbar loggedIn={true}></Navbar>
        <CircularProgress color="inherit" />
    </div>
    )


}
