import * as React from 'react';
import './App.css';
import Navbar from './components/Navbar/Navbar.js'
import MainContainer from './components/MainContainer/MainContainer'
import { useCookies } from "react-cookie";
import { CircularProgress } from "@mui/material"
import { Navigate } from "react-router-dom";
var i = 0;
function App() {
  i++
  console.log(i)
  const [cookies, setCookie] = useCookies(['user']);
  const [state, setState2] = React.useState({ 
    redirect: null,
    account: null
  });
  if (state.redirect) {
      return <Navigate to={state.redirect} />
  }

  if (state.account && cookies.token != null) {
    console.log("loaded main dashboard")
    return (
      <div className="App">
        <Navbar username={state.account.name1} notifications={state.account.messages} loggedIn={true}></Navbar>
        <MainContainer account={state.account} handler={setState2}></MainContainer>
      </div>
    );
  }
  console.log("making request")
  const url = process.env.REACT_APP_API_URL + "/dashboard";
  const bearer = "Bearer " + cookies.token
  console.log(bearer)
  const options = {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": bearer
      }
  };
  fetch(url, options)
  .then((response) => response.json())
  .then((data) => {
      console.log(data);
      if (data.error) {
        setState2({
          redirect: '/login'
        })
      } else {
        console.log("request returned, setting state.account")
        setState2({
          redirect: state.redirect,
          account: data
        })
      }
      
  });
  console.log("returning loading circle")
  return (
    <div className="App">
      <Navbar loggedIn={true}></Navbar>
      <CircularProgress color="inherit" />
  </div>
  )
}

export default App;
