import * as React from 'react';
import { Grid, Typography} from '@mui/material'
import './FriendsSummary.css'
import HabitCard from '../../components/HabitCard/HabitCard.js'

export default function FriendsSummary(props) {
    var failed = []
    var success = []
    for (var i=0; i<props.account.others_habits.length; i++) {
        if (props.account.others_habits[i].streak === 0) {
            failed.push(
                <HabitCard habit={props.account.others_habits[i]} key={props.account.others_habits[i].name1 + "_failed"} owner={false} owner_name={props.account.others_habits[i].owner_name} appHandler={props.appHandler}></HabitCard>
            )
        } else {
            success.push(
                <HabitCard habit={props.account.others_habits[i]} key={props.account.others_habits[i].name1 + "_success"} owner={false} owner_name={props.account.others_habits[i].owner_name} appHandler={props.appHandler}></HabitCard>
            )
        }
        
    }
    success.sort((a, b) => b - a);

    var successSection = (
        <>
            <Grid item xs={12}>
                <Typography style = {{float:'left', paddingLeft:'1%'}}>
                    Longest Streaks:
                </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingLeft:'3vw', paddingRight:'.5vw', marginRight:'5vw'}}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    {success}
                </Grid>
            </Grid>
        </>
    )
    var failedSection = (
        <>
            <Grid item xs={12}>
                <Typography style = {{float:'left', paddingLeft:'1%'}}>
                    Failed:
                </Typography>
            </Grid>
            <Grid item xs={12} style={{paddingLeft:'3vw', paddingRight:'.5vw', marginRight:'5vw'}}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    {failed}
                </Grid>
            </Grid>
        </>
    )
    if (failed.length === 0 && success.length === 0) {
        return (
            <></>
        )
    }
    return (
        <Grid container rowSpacing={1.5} id="grid-container">
            <Grid item xs={12} className='bottom-border' style={{marginRight:'5vw'}}>
                <Typography variant="h6" color="inherit" className="font-size" component="div" style={{width:'max-content',float:"right", marginTop:'2%', paddingRight:"1vw"}}>
                    Friends Summary
                </Typography>
                
            </Grid>
            {failed.length > 0 && failedSection}
            {success.length > 0 && successSection}
            <br></br>
            <br></br>
        </Grid>
    )
}
