import * as React from 'react';
import { Grid } from '@mui/material'
import Card from '@mui/material/Card';
import './HabitGrid.css'

export default function HabitGrid(props) {
    var rows = []
    var i = 0;
    if (props.days.length >30) {
        i = props.days.length - 30
    }

    while (i<props.days.length) {
        rows.push(
            <Grid item xs={1} key={i}>
                    <Card variant="elevation" className={props.days[i] === 1 ? "habit-box-green8":"habit-box-red8"}></Card>
            </Grid>
        )
        i++
    }
    return (
        <div style={{padding:"1em"}}>
            <Grid container rowSpacing={1} columnSpacing={.3}>
                {rows}
            </Grid>
        </div>
        
    )
}