import * as React from 'react';
import Navbar from '../Navbar/Navbar.js'
import { useCookies } from "react-cookie";
import LeftNavbar from '../LeftNavbar/LeftNavbar';
import { Navigate } from "react-router-dom";
import { CircularProgress, Grid, Button, TextField, Typography, Card } from "@mui/material"
export default function ProfilePage() {
  const [cookies, setCookie] = useCookies(['user']);
  const [state, setState2] = React.useState({ 
    redirect: null,
    account: null
  });
  const [clicks, SetClicks] = React.useState(0)
  if (state.redirect) {
      return <Navigate to={state.redirect} />
  }

  if (state.account && cookies.token != null) {
    console.log("loaded Profile Page")
    return (
      <div className="App">
        <Navbar username={state.account.name1} notifications={state.account.messages} loggedIn={true}></Navbar>
         
        <Grid container style={{height:'100%'}}>
            <LeftNavbar page=""/>
            <Grid item xs={true} className='main-grid flex-col-scroll' style={{maxHeight: '100vh', overflow: 'auto', paddingLeft:'2vw'}}>
              <h1>{state.account.name1} you really shouldn't look at this page it does nothing</h1> 
              <h4>Habits</h4>
              <ul style={{width:'15%'}}>
                {state.account.habits.map(habit => <li key={habit.id}>{habit.name1}</li>)}
              </ul>
              
              <h4>Friends</h4>
              <ul style={{width:'15%'}}>
                {state.account.friends.map(friend=> <li key={friend.id}>{friend.name1}</li>)}
              </ul>
              <Button variant="contained" color="error" onClick={() => {
                var msg=""
                if (clicks == 0) {
                  msg= "Dude why"
                } else if (clicks == 1) {
                  msg="I thought we were friends"
                } else if (clicks == 2) {
                  msg="I'll sell your data..."
                } else {
                  msg = "I actually didn't code this so you can't"
                }
                SetClicks(clicks+1)
                alert(msg)}}>Delete Account</Button>
            </Grid>
        </Grid>
      </div>
    );
  }
  console.log("making request")
  const url = process.env.REACT_APP_API_URL + "/dashboard";
  const bearer = "Bearer " + cookies.token
  console.log(bearer)
  const options = {
      method: "GET",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          "Authorization": bearer
      }
  };
  fetch(url, options)
  .then((response) => response.json())
  .then((data) => {
      console.log(data);
      if (data.error) {
        setState2({
          redirect: '/login'
        })
      } else {
        console.log("request returned, setting state.account")
        setState2({
          redirect: state.redirect,
          account: data
        })
      }
      
  });
  console.log("returning loading circle")
  return (
    <div className="App">
      <Navbar></Navbar>
      <CircularProgress color="inherit" />
  </div>
  )
}
